import { redirect } from "vike/abort";
import { getStore } from "#redux/store";

// A logged in user in onboarding should always be redirected back to onboarding, no matter where they are
export async function guard(pageContext) {
  const store = pageContext.isClientSideNavigation ? getStore().reduxStore : pageContext.preloadedStore;
  const { user: { currentUserId } } = store.getState();
  if (currentUserId) {
    throw redirect("/feed");
  }
};
